import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCarList } from "../home/actions";
import {updateSelectedSuggestions,
    updateFilterOptionData,
    clearAllFilters,
    updateAppliedFilters,
    updateAppliedSuggestions} from "../filters/actions";

import { reloadCarList } from "../car-listing/actions";

import FilterByPriceSlider from "./component";

const mapStateToProps = ({
    homeCarList: {
        quotedPriceContent,
        config,
        metaContent
    },
    filters: {
        allFilters
    },
    tradeIn: {
        userTradeInData,
        tradeInToggleSelected
    }

}) => ({
    allFilters,
    quotedPriceContent,
    config,
    metaContent,
    userTradeInData,
    tradeInToggleSelected
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCarListConnect: fetchCarList,
            updateSelectedSuggestionsConnect: updateSelectedSuggestions,
            updateFilterOptionDataConnect: updateFilterOptionData,
            clearAllFiltersConnect: clearAllFilters,
            updateAppliedFiltersConnect: updateAppliedFilters,
            updateAppliedSuggestionsConnect: updateAppliedSuggestions,
            reloadCarListConnect: reloadCarList

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FilterByPriceSlider);
